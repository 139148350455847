<template>
  <div>
    <b-card class="min-vh-100">
      <div v-if="masters.kfs.length">
        <b-row>
          <b-col
            sm="2"
            class="d-flex align-items-center"
          >
            <p class="mb-0">
              Lihat data:
            </p>
          </b-col>
          <b-col sm="3">
            <b-form-select
              v-model="config.additional_params.serviceType"
              :options="masters.filterBy"
            />
          </b-col>
          <b-col sm="2">
            <v-select
              id="tag"
              v-model="config.additional_params.kfsId"
              label="name"
              class="custom-v-select"
              placeholder="Pilih KFS"
              :clearable="false"
              :options="masters.kfs"
              :reduce="options => options.id"
            >
              <template #no-options="{ search, searching, loading }">
                Tidak ditemukan hasil pencarian
              </template>
            </v-select>
          </b-col>
          <b-col sm="2">
            <v-select
              id="tag"
              v-model="config.additional_params.staffAccountId"
              clearable
              :disabled="!kfsId"
              label="fullName"
              class="custom-v-select"
              placeholder="Semua Staff"
              :options="masters.staff"
              :reduce="options => options.accountId"
            >
              <template #no-options="{ search, searching, loading }">
                Staff tidak tersedia
              </template>
            </v-select>
          </b-col>
          <b-col sm="3">
            <date-range-picker
              id="rangeDate"
              :date-range="vars.rangeDate"
              @update="updateDate"
            />
          </b-col>
        </b-row>
        <!-- <AppEchartBar :option-data="po.option" /> -->
        <apexchart
          width="100%"
          type="bar"
          :options="chartOptions"
          :series="series"
        />
        <div>
          <b-row>
            <b-col sm="6">
              <h3>Ringkasan</h3>
            </b-col>
          </b-row>
          <b-row
            v-for="resume, idx in resumes"
            :key="idx"
            class="border-bottom mx-3"
          >
            <b-col sm="6">
              <p class="mt-1 mb-0 font-weight-bold">
                {{ resume.text }}
              </p>
            </b-col>
            <b-col
              sm="6"
              class="text-right"
            >
              <p class="mt-1 mb-0 font-weight-bold">
                {{ resume.value }}
              </p>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-3">
          <ResponseAlert ref="response" />
          <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="col-md-6 col-12 px-0 order-1 order-md-0 d-flex flex-column flex-md-row" />
            <div class="order-0 order-md-1 mb-1 mb-md-0">
              <b-button
                variant="secondary"
                class="btn-min-width rounded mr-1 full-width-responsive mb-1 mb-md-0"
                @click.prevent="exportExcel"
              >
                Download report
              </b-button>
            </div>
          </div>
          <b-col
            v-if="config.rows.length"
            sm="12"
          >
            <div class="table-responsive">
              <b-table
                id="my-table"
                class="custom-table"
                :items="config.rows"
                :per-page="config.per_page"
                :current-page="config.page"
                responsive
                style="white-space: nowrap;"
              />
              <div class="d-flex justify-content-between mt-2">
                <b-form-select
                  v-model="config.per_page"
                  :options="perPageOptions"
                  class="w-auto"
                />
                <b-pagination
                  v-model="config.page"
                  :total-rows="config.rows.length"
                  :per-page="config.per_page"
                  aria-controls="my-table"
                />
              </div>
            </div>
          </b-col>
          <b-col v-else>
            <p class="text-center py-5">
              No record data found
            </p>
          </b-col>
        </b-row>
      </div>
      <div
        v-else
        class="d-flex justify-content-center min-vh-100 align-items-center"
      >
        <h4>
          KFS Not Found, please create KFS to see reporting menu.
        </h4>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormSelect, BButton, BPagination, BTable,
} from 'bootstrap-vue'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import api from '@/utils/api'
import vSelect from 'vue-select'
import { addToObject, exportExcel } from '@/utils/helpers'

export default {
  components: {
    BTable,
    BPagination,
    BButton,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    DateRangePicker,
    vSelect,
  },
  metaInfo: {
    title: 'Reporting SO',
  },
  data() {
    return {
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: '70%',
          },
        },
        noData: {
          text: 'No data found',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
            export: {
              csv: {
                filename: 'Reporting Chart',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: 'Reporting Chart',
              },
              png: {
                filename: 'Reporting Chart',
              },
            },
            autoSelected: 'zoom',
          },
        },
        colors: ['#B22A2E'],
        fill: {
          colors: '#B22A2E',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val.toLocaleString().replace(/[.,]/g, m => (m === '.' ? ',' : '.'))}`
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: value =>
              // Nine Zeroes for Billions
              (Math.abs(Number(value)) >= 1.0e+9
                ? `${(Math.abs(Number(value)) / 1.0e+9).toFixed(2)} Miliar`
              // Six Zeroes for Millions
                : Math.abs(Number(value)) >= 1.0e+6
                  ? `${(Math.abs(Number(value)) / 1.0e+6).toFixed(2)} Juta`
                // Three Zeroes for Thousands
                  : Math.abs(Number(value)) >= 1.0e+3
                    ? `${(Math.abs(Number(value)) / 1.0e+3).toFixed(2)} Ribu`
                    : Math.abs(Number(value)))
            ,
          },

        },
      },

      series: [{
        name: 'Sales',
        data: [],
      }],

      models: {
        summary: {
          costPrice: null,
          discount: null,
          grossSales: null,
          itemSales: null,
          netSales: null,
          refund: null,
          salesProfit: null,
          tax: null,
          totalSales: null,
          voucher: null,
        },
      },
      vars: {
        rangeDate: {
          startDate: null,
          endDate: null,
        },
      },
      config: {
        additional_params: {
          startDate: null,
          endDate: null,
          kfsId: null,
          staffAccountId: null,
          serviceType: 'by-service',
        },
        uri: this.$route.meta.link,
        api: 'reports',
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [],
      },
      masters: {
        kfs: [],
        staff: [],
        filterBy: [
          {
            text: 'Data Penjualan by Service',
            value: 'by-service',
          },
          {
            text: 'Data Penjualan by Customer',
            value: 'by-customer',
          },
          {
            text: 'Data Penjualan by Lokasi',
            value: 'by-location',
          },
          {
            text: 'Data Penjualan by Product',
            value: 'by-product',
          },
          {
            text: 'Data Penjualan by Type',
            value: 'by-type',
          },
          {
            text: 'Data Penjualan by Invoice Detail',
            value: 'by-invoice',
          },
        ],
      },
    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    kfsId() {
      return this.config.additional_params.kfsId
    },
    serviceType() {
      return this.config.additional_params.serviceType
    },
    additional_params() {
      return JSON.stringify(this.config.additional_params)
    },
    resumes() {
      const { summary } = this.models
      const fc = this.formatCurrency
      return [
        {
          text: 'Jumlah',
          value: summary.itemSales,
        },
        {
          text: 'Kotor',
          value: fc(summary.grossSales),
        },
        {
          text: 'Diskon',
          value: fc(summary.discount),
        },
        {
          text: 'Pengembalian',
          value: summary.refund,
        },
        {
          text: 'Net',
          value: fc(summary.netSales),
        },
        {
          text: 'Pajak',
          value: fc(summary.tax),
        },
        {
          text: 'Total penjualan',
          value: fc(summary.totalSales),
        },
        {
          text: 'Harga modal',
          value: fc(summary.costPrice),
        },
        {
          text: 'Profit',
          value: fc(summary.salesProfit),
        },
        {
          text: 'Penggunaan Voucher',
          value: summary.voucher,
        },
      ]
    },
  },
  watch: {
    kfsId() {
      this.config.additional_params.staffAccountId = null
      this.getStaff()
    },
  },
  mounted() {
    this.config.additional_params.startDate = this.vars.rangeDate.startDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.config.additional_params.endDate = this.vars.rangeDate.endDate = this.moment().endOf('month').format('YYYY-MM-DD')
    this.$axios.get(`${api.list_kfs}`).then(res => {
      this.masters.kfs = [...res.data.data]
      this.config.additional_params.kfsId = this.masters.kfs[0].id

      this.$nextTick(() => {
        this.getReporting()

        this.$watch('additional_params', () => {
          this.getReporting()
        })
      })
    })
  },
  methods: {
    getStaff() {
      this.$axios.get(`${api.list_staff}?kfsId=${this.config.additional_params.kfsId}`).then(res => {
        const { data } = res.data
        this.masters.staff = data.map(staff => {
          staff.fullName = staff.profile.fullName
          staff.accountId = staff.profile.accountId
          return staff
        })
      })
    },
    exportExcel() {
      const {
        serviceType, kfsId, startDate, endDate, staffAccountId,
      } = this.config.additional_params
      const kfsName = this.masters.kfs.find(kfs => kfs.id === kfsId).name
      exportExcel(`Data Sales Order ${serviceType} (${kfsName} ${startDate} - ${endDate})`, `${this.config.api}/excell`, {
        kfsId,
        startDate,
        endDate,
        staffAccountId,
        serviceType,
      })
    },
    getCharts() {
      return this.$axios.get(`${this.config.api}/chart`, { params: this.config.additional_params }).then(res => {
        const { chart } = res.data.data
        const series = []
        const categories = []
        chart.forEach(value => {
          categories.push(value.label)
          series.push(value.data)
        })
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories,
            },
          },
        }
        this.series = [{
          data: series,
        }]
      })
    },
    getReporting() {
      this.getCharts().then(() => {
        this.$axios.get(this.config.api, {
          params: this.config.additional_params,
        })
          .then(res => {
            const { rows, summary: summaries } = res.data.data
            const fc = this.formatCurrency
            this.config.rows = rows.map(row => {
              let item = {
                item_terjual: row.itemSales,
                gross_sales: fc(row.grossSales),
                discount: fc(row.discount),
                net_sales: fc(row.netSales),
                tax: fc(row.tax),
                penukaran_voucher: row.voucher,
                total_sales: fc(row.totalSales),
              }

              if (this.serviceType === 'by-service') {
                item = addToObject(item, 'item', row.item, 0)
                item = addToObject(item, 'refund', fc(row.refund), 4)
                item.cost_price = fc(row.costPrice)
                item.sales_profit = fc(row.salesProfit)
              }

              if (this.serviceType === 'by-customer') {
                item = addToObject(item, 'customer', row.customer, 0)
              }

              if (this.serviceType === 'by-location') {
                item = addToObject(item, 'location', row.location, 0)
              }

              if (this.serviceType === 'by-product') {
                item = addToObject(item, 'item', row.product, 0)
              }
              if (this.serviceType === 'by-type') {
                item = addToObject(item, 'type', row.type, 0)
              }
              if (this.serviceType === 'by-invoice') {
                delete item.item_terjual
                delete item.net_sales
                delete item.total_sales

                item = addToObject(item, 'invoice', row.invoiceCode, 0)
                item = addToObject(item, 'date', row.date, 1)
                item = addToObject(item, 'sales_by', row.salesBy, 2)
                item = addToObject(item, 'serve_by', row.serveBy, 3)
                item = addToObject(item, 'sales_source', row.salesSource, 4)
                item = addToObject(item, 'sales_type', row.type, 5)
                item = addToObject(item, 'sales_status', row.statusText, 6)
                item = addToObject(item, 'item', row.items, 7)
                item = addToObject(item, 'customer', row.customer, 8)
                // item = addToObject(item, 'customer_note', row.customer_note, 9)
                item = addToObject(item, 'qty', row.itemSales, 9)
                item.sales_amount = fc(row.totalAmount)
                item.payment_method = row.paymentMethod
              }

              if (this.serviceType !== 'by-invoice' && this.serviceType !== 'by-customer') {
                item = addToObject(item, 'refund', row.refund, 4)
              }

              return item
            })

            const rowsLength = this.series[0].data.length
            const columnWidth = rowsLength > 7 ? '85%' : rowsLength > 3 ? '70%' : rowsLength > 1 ? '40%' : '20%'
            this.chartOptions = {
              ...this.chartOptions,
              ...{
                plotOptions: {
                  bar: {
                    columnWidth,
                  },
                },
              },
            }

            this.config.total_data = rows.length
            this.models.summary = summaries
          })
      })
    },
    updateDate(value) {
      const { startDate, endDate } = value
      this.config.additional_params.startDate = this.moment(startDate).format('YYYY-MM-DD')
      this.config.additional_params.endDate = this.moment(endDate).format('YYYY-MM-DD')
    },
  },
}
</script>